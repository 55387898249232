import React, {useState, useEffect, useRef} from "react"

import '../styles/navigation.scss'
import { lerp, smoothScrollTo } from '../utils/utils.js'


const Navigation = ({introductionReference, projectsReference, contactReference}) => {
  const [scrollingDirection, setScrollingDirection] = useState('down');
  const [previousYPoistion, setPreviousYPosition] = useState(0);

  const [isAboutHighlighted, setIsAboutHighlighted] = useState(false)
  const [isProjectsHighlighted, setIsProjectsHighlighted] = useState(false)
  const [isContactHighlighted, setIsContactHighlighted] = useState(false)

  const setButtonHighlight = () => 
  {
    setIsContactHighlighted(false);
    setIsAboutHighlighted(false);
    setIsProjectsHighlighted(false);

    let scrollYBottom = window.scrollY + (window.innerHeight / 2);

    if (scrollYBottom > contactReference.current.offsetTop)     
    {
      setIsContactHighlighted(true);
    }
    else if (scrollYBottom > projectsReference.current.offsetTop)
    {
      setIsProjectsHighlighted(true);
    }
    else
    {
      setIsAboutHighlighted(true);
    }
  }

  const handleScroll = () => {
    setButtonHighlight();
    if (window.scrollY > previousYPoistion)
    {
      setScrollingDirection("down");
    }
    else 
    {
      setScrollingDirection("up");
    }
    setPreviousYPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  })

  return (
    <nav className="navigation">
      <div className={`navigation__entry ${isAboutHighlighted ? 'navigation__entry--highlighted' : '' }`}
        onClick={
          () => {smoothScrollTo(introductionReference)}
        }
      >
        about <br/>me
      </div>

      <div className={`navigation__entry ${isProjectsHighlighted? 'navigation__entry--highlighted': ''}`}
        onClick={
          () => {smoothScrollTo(projectsReference)} 
        }
      >
        selected <br/>work
      </div>

      <div className={`navigation__entry ${isContactHighlighted ? 'navigation__entry--highlighted' : ''}`}
      onClick={
        () => {smoothScrollTo(contactReference)}
      }>
        hit <br/>me up
      </div>
    </nav>
  )
}

export default Navigation